html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
}

body {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	font-family: 'Inter', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
}

.login-form {
    width: 100%;
    height: 100%;
    background: url(src/assets/img/background.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.login-block {
	width: 400px;
    background: linear-gradient(rgb(77 102 159 / 75%), rgb(255 255 255 / 90%));
	padding: 2em;
	overflow: hidden;
	-webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .04);
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, .04);
    text-align: center;
    border-radius: 4px;
}

.login-form .form-group {
    position: relative;
}

.login-form .form-control {
    border-width: 2px;
    font-size: 1em;
    padding: 1.375rem .75rem;
    border-color: #3a5497;
}

.login-form .form-control:focus {
    box-shadow: none;
}

.login-form .form-control.ng-invalid.ng-dirty {
    border-color: #f44336;
}

.login-form .link {
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    color: var(--gray-dark);
}

.link:hover {
    color: var(--blue);
    text-decoration: underline;
}

.decorated{
    overflow: hidden;
    text-align: center;
}

.decorated > span{
   position: relative;
   display: inline-block;
}

.decorated > span:before, .decorated > span:after{
   content: '';
   position: absolute;
   top: 50%;
   border-bottom: 2px solid var(--gray-dark);
   width: 140px;
   margin: 0 20px;
}

.decorated > span:before{
   right: 100%;
}

.decorated > span:after{
   left: 100%;
}

.validation-message {
    position: absolute;
    right: 5px;
    font-size: 12px;
    bottom: 0px;
    font-weight: 500;
    color: var(--red);
    text-transform: capitalize;
}

.btn-success.disabled,
.btn-success:disabled {
	cursor: not-allowed;
}

/*My CSS END*/

@media (max-width: 767px) {
    .login-block {
        width: 90%;
    }
}







